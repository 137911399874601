@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    @apply select-none overflow-x-hidden;
  }

  body {
    background-color: #0070ba;
  }

  @font-face {
    font-family: "Molot-Black-Italic";
    src: url("./assets/fonts/Supermolot-BlackItalic.otf");
  }
  
  @font-face {
    font-family: "CCWildWords-Bold-Italic";
    src: url("./assets/fonts/ccwildwords-bolditalic.otf");
  }
  
  @font-face {
    font-family: "CCWildWords-Italic";
    src: url("./assets/fonts/ccwildwords-italic.otf");
  }

  input, button {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
  } 
}