.layout {
  @apply w-screen h-screen max-w-screen-lg;
  background: url('../assets/i/bg-side.png') no-repeat 100% 100% / contain,
    url('../assets/i/bg.png') 0% 0% / contain;
}

/* Generic message bubble */
.message-bubble {
  @apply flex justify-center items-center h-[300px]
    p-6 pb-20 mt-16 z-20 text-2xl text-center font-ww-bold-italic;
  background: url('../assets/i/bubble.png') no-repeat 0 0 / 100% 100%;
}

/* Homepage/screensaver */
.screensaver-wrapper {
  @apply h-full;
  background: url('../assets/i/shadow-left.png') no-repeat 0% -7rem / contain,
    url('../assets/i/shadow-right.png') no-repeat 100% -7rem / contain;
}

/* Settings Course */
.settings-course-wrapper {
  background:
    url('../assets/i/shadow-left.png') no-repeat 0% 0% / contain,
    url('../assets/i/shadow-right.png') no-repeat 100% 100% / contain,
    url('../assets/i/bg-side.png') no-repeat 100% 100% / contain,
    url('../assets/i/course/course-bg.png') no-repeat 0 100%  / cover;
}

.course-carousel-wrapper .use-spring-carousel-item {
  @apply flex flex-col w-full items-center;
}

.course-carousel-wrapper .course-image-wrapper {
  @apply max-w-[98%] h-[20rem] flex items-end mb-4 grow;
}

.course-carousel-wrapper .course-image-wrapper img {
  @apply max-h-full;
}

@media screen and (max-height: 640px) {
  .course-carousel-wrapper .course-image-wrapper {
    @apply h-[15rem];
  }
}

/* Settings Player */
.settings-player-wrapper {
  background:
    url('../assets/i/shadow-left.png') no-repeat 0% 0% / contain,
    url('../assets/i/shadow-right.png') no-repeat 100% 100% / contain;
}

.player-carousel-wrapper .use-spring-carousel-item {
  @apply flex flex-col w-full items-center;
}

.player-carousel-wrapper .player-image-wrapper {
  @apply max-w-[98%] h-[15rem] flex items-end mb-16 grow;
}

.player-carousel-wrapper .player-image-wrapper img {
  @apply max-h-full;
}

.player-label-image-wrapper {
  @apply flex flex-row justify-center;
}

.player-label-image-wrapper img {
  @apply max-w-[10rem];
}

@media screen and (max-height: 640px) {
  .player-carousel-wrapper .player-image-wrapper {
    @apply h-[10rem];
  }
}

/* Tutorial jump */
.tutorial-jump-wrapper {
  background:
    url('../assets/i/shadow-left.png') no-repeat 0% 0% / contain,
    url('../assets/i/shadow-right.png') no-repeat 100% 100% / contain;
}

/* Prev/Next buttons */
.direction-button {
  @apply transition-all w-16 absolute bottom-0;
}

.direction-button.prev {
  @apply active:-translate-x-1 left-0;
}

.direction-button.next {
  @apply active:translate-x-1 right-0;
}

/* Misc */
.button-primary {
  @apply text-white font-molot-bold-italic rounded-full p-1 backdrop-blur-sm;
  background-color: rgba(0, 0, 0, .3);
}

.button-primary span {
  @apply block w-full h-full py-2 px-4 rounded-full;
  background: linear-gradient(180deg, #C96800 0%, #FF8B00 100%);
  box-shadow: 8.28678e-16px 13.5333px 27.0667px rgba(48, 25, 0, 0.24), inset -2.57811e-16px -4.21037px 4.21037px #BF6300, inset 2.57811e-16px 4.21037px 4.21037px #FF9000;
}

.button-primary:active span {
  @apply shadow-inner;
}

.button-action {
  background: rgba(5, 44, 125, .5);
  @apply block w-72 h-72 relative rounded-full border border-gray-700 backdrop-blur-sm
    flex justify-center items-center;
}

.button-action > div {
  @apply w-64 h-64 rounded-full relative flex justify-center items-center;
  background: conic-gradient(from 180deg at 50% 50%, #F27325 -148.28deg, #F49142 94.92deg, #FCE2C9 143.26deg, #F27325 211.72deg, #F49142 454.92deg);
}

.button-action > div::after {
  @apply block w-60 h-60 rounded-full absolute z-10;
  content: '';
  background: linear-gradient(354deg, #864500 9.21%, #AE5A00 26.71%, #E77800 110.26%);
}

.button-action > div > div {
  @apply w-44 h-44 rounded-full relative z-20 flex justify-center items-center;
  background: conic-gradient(from 180deg at 50% 50%, #F27325 -148.28deg, #F49142 94.92deg, #FCE2C9 143.26deg, #F27325 211.72deg, #F49142 454.92deg);
}

.button-action:active > div > div {
  @apply scale-90;
  background: conic-gradient(from 180deg at 50% 50%, #F27325 -148.28deg, #F49142 94.92deg, #fcd6b2 143.26deg, #F27325 211.72deg, #F49142 454.92deg);
}

.button-action > div > div::after {
  @apply block w-40 h-40 rounded-full absolute z-30;
  content: '';
  background: linear-gradient(180deg, #8B4800 0%, #FF8500 100%);
}

@media screen and (max-height: 640px) {
  .button-action {
    @apply w-52 h-52;
  }
  .button-action > div {
    @apply w-48 h-48;
  }
  .button-action > div::after {
    @apply w-44 h-44;
  }
  .button-action > div > div {
    @apply w-32 h-32;
  }
  .button-action > div > div::after {
    @apply block w-28 h-28;
  }
}
